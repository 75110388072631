/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background-image: linear-gradient(to top, rgb(56, 56, 56), rgb(56, 56, 56));
  background-repeat: no-repeat;
  height: 100vh !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-image: linear-gradient(to top, rgb(46, 98, 235), rgb(92, 205, 255));
  width: 2px;
  height: 2px!important;
  border-radius: 50%;
}
::-webkit-scrollbar {
  width: .3rem;
  height: 2px;
}

@media only screen and (max-width:1024px) {
  .icon-img {
    display: none;
    visibility: hidden;
  }
  #coinIcon {
    display: none;
    visibility: hidden;
  }
}

/* .link-underline {
  border-bottom-width: 0;
  background-image: linear-gradient(transparent, transparent), linear-gradient(#10E2FF, #10E2FF);
  background-size: 0 2px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  transition: background-size .5s ease-in-out;
}

.link-underline-black {
  background-image: linear-gradient(transparent, transparent), linear-gradient("#10E2FF", "#10E2FF")
}

.link-underline:hover {
  background-size: 100% 2px;
  background-position: 0 100%
} */


a {
  display: table-cell;
  position: relative;
  padding: 1px 0;
}

a:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #10E2FF;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
  border-radius: 5px;
}

a:hover:after {
  width: 100%;
  left: 0;
}

.icon-img {
  position: absolute;
  right: 0;
  bottom: 0;
  animation-name: bounceInOut;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
#coinIcon {
  position: absolute;
  left: 0;
  top: -130px;
  animation-name: coinBounce;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
@keyframes coinBounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(25px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes bounceInOut {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(25px);
  }
  100% {
    transform: translateY(0px);
  }
}

  
@keyframes fadeInOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

#learnSection {
  animation: fadeInOut 10s infinite;  /* Adjust the duration as needed */
  background-size: cover; /* Ensure the background covers the entire container */
}


/* google fonts */

.anton-sc-regular {
  font-family: "Anton SC", sans-serif;
  font-weight: 400;
  font-style: normal;
}

/* Notification page toggle button  css properties*/

.slider::before {
  position: absolute;
  content: "OFF";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  font-size: 12px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-box:checked + .slider {
  background-color: #00FFC2D9;
}

.input-box:focus + .slider {
  box-shadow: 0 0 1px #00FFC2D9;
}

.input-box:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  content: "ON";
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

@keyframes moveLeftToRight {
  0% {
      transform: translateX(0); /* Start at the original position */
  }
  100% {
      transform: translateX(100%); /* End at the right side */
  }
}

.animate-moveLeftToRight {
  animation: moveLeftToRight 3s linear infinite; /* Adjust duration and timing */
}
#circle {
  fill: transparent;
}

.circle-svg {
  width: 450px;
  height: 450px;
}

@media (max-width: 1024px) {
  .circle-svg {
    width: 300px;
    height: 300px;
  }
}

.animate-spin {
  animation-name: spin;
  animation-duration: 20900ms;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}